/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid, Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { Icon } from '@plone/volto/components';
import moveupSVG from '@plone/volto/icons/move-up.svg';
import movedownSVG from '@plone/volto/icons/move-down.svg';
import emailSVG from '@plone/volto/icons/email.svg';
import dsaLogo from './DSA-Logo-Lang-RGB-ww.svg';
import umrLogo from './UMR_Logo_weiss.svg';
import UMRLogo from './UMR_Logo.png';
import URLogo from './UR_Logo.png';
import ZfLbLogo from './ZfLMarburg-Zuschnitt.jpg';
import GGSGLogo from './GGSG_Logo.png';
import IGDDLogo from './IGDD_Logo.png';
import DSALogo from './DSA-Logo-Kurz-RGB.png';
import './Footer.css';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl, available }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const [open, setOpen] = React.useState(true);

  let buttonIcon;
  if (open) {
    buttonIcon = <Icon name={moveupSVG} size="24px" color="white" />;
  } else {
    buttonIcon = <Icon name={movedownSVG} size="24px" color="white" />;
  }

  return (
    <div>
      <Container style={{ backgroundColor: 'white', borderTop: '2px solid #23396e' }}></Container>
      <Segment role="contentinfo" vertical padded inverted textAlign="center" style={{ backgroundColor: 'white' }}>
        <List inverted>
          <List.Item style={{ fontWeight: 'bold', color: '#23396e', fontSize: '16px' }}>
            Cooperation Partners
          </List.Item>
        </List>
        <Container>
          <Grid columns={3}>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://www.uni-marburg.de/de"><img src={UMRLogo} alt="Logo" id="UMRLogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://www.uni-marburg.de/de/zfl"><img src={ZfLbLogo} alt="Logo" id="ZfLbLogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://www.uni-marburg.de/de/fb09/dsa"><img src={DSALogo} alt="Logo" id="DSALogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
          <Grid columns={3}>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://www.germanistische-sprachgeschichte.de/"><img src={GGSGLogo} alt="Logo" id="GGSGLogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://igdd.org/"><img src={IGDDLogo} alt="Logo" id="IGDDLogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  <a className="logoForm" href="https://www.uni-rostock.de/"><img src={URLogo} alt="Logo" id="URLogo" /></a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>

      <Segment role="contentinfo" vertical padded inverted textAlign="center" id="dsafooter">
        <Container>
          <Grid columns={3}>
            <Grid.Column>
              <List inverted>
                <List.Item style={{ fontWeight: 'bold' }}>
                  DSA | Forschungszentrum Deutscher Sprachatlas
                </List.Item>
                <List.Item>
                  Pilgrimstein 16
                </List.Item>
                <List.Item>
                  35032 Marburg
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List inverted>
                <List.Item>
                  +49 6421 28-22483
                </List.Item>
                <List.Item>
                  +49 6421 28-28936
                </List.Item>
                <List.Item href="mailto:dsa@staff.uni-marburg.de">
                  <span id="iconFooter">
                    <Icon name={emailSVG} size="18px" color="white" id="icon" />
                    <span>
                      dsa@staff.uni-marburg.de
                    </span>
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
               <List inverted>
                <List.Item>
                  <img src={dsaLogo} alt="Logo" id="dsaLogo-long" />
                </List.Item>
                </List>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>

      <Segment role="contentinfo" vertical padded inverted textAlign="center" id="umrfooter">


        <Container id="sub-footer">
          <Grid columns={1}>
            <Grid.Column>
              <List horizontal inverted>
                <List.Item className="subFooterItem">
                  <UniversalLink href="/impressum">
                    Impressum
                  </UniversalLink>
                </List.Item>
                <List.Item className="subFooterItem">
                  <UniversalLink href="/datenschutzerklarung">
                    Datenschutz
                  </UniversalLink>
                </List.Item>
                <List.Item className="subFooterItem">
                  <UniversalLink href="/erklarung-zur-barrierefreiheit">
                    Barrierefreiheit
                  </UniversalLink>
                </List.Item>
              </List>
            </Grid.Column>

          </Grid>
        </Container>

      </Segment>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
