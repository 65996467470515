/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UMRLogo from './UMR_Logo_weiss.svg';

import {
  Navigation
} from '@plone/volto/components';

import './Header.css';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth <= 1000;
    this.setState({ isMobile });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {

    const { isMobile } = this.state;


    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header" id="header">
            <a className="logoForm" href="https://www.uni-marburg.de/de"><img src={UMRLogo} alt="Logo" id="UMRLogo" /></a>
          </div>
          <div>
            <Container style={{ color: '#297acc', display: 'flex', alignItems: 'center', justifyContent: 'left', padding: '2rem 0' }} className="headline">
              <div style={{ fontSize: '3rem', paddingLeft: '1%'}}>
                Sprachvariation@Schule
              </div>
            </Container>
            <div className="logo-nav-wrapper">
              <Navigation pathname={this.props.pathname} />
            </div>
          </div>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);
