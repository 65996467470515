/**
 * View image block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { LinkMore } from '@plone/volto/components';
import { FormattedDate } from '@plone/volto/components';
/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className="block hero">
    <div className="block-inner-wrapper">
      {data.url && (
        
        <img
          src={`${flattenToAppURL(data.url)}/@@images/image`}
          alt=""
          class="logoForm"
          loading="lazy"
          style={{ objectFit: 'contain', width: '300px', height: '300px', objectPosition: 'center', overflow: 'hidden', alignItems: 'center' }}
        />
       
      )}
      <div className="hero-body">
        <div className="hero-text">
          {data.title && <h3>{data.title}</h3>}
          {data.description && <p>{data.description}</p>}
        </div>
        {data.description && <p style={{ marginRight: 'auto', display: 'flex', alignItems: 'center', position: 'absolute', bottom: '5%' }}>{data.date}</p>}
        <LinkMore data={data} />
      </div>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
